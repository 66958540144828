<template>
    <div class="w-100">
        <Header />
        <div class="mt-6 p-4">
            <div class="zc-container zc-w-22">
                <img src="@/assets/zaajira-candidate/assets/bg-images/user_profile_cover.png"
                    style="width: 100%;height: 3.625rem;" class="img-fluid" />
                <b-row>
                    <b-col>
                        <div style="margin-top: -50px;" class="mb-3 ml-4">
                            <b-avatar size="6rem" style="background: var(--neutral-white);border: 4px solid #FCFCFC;">
                                <img :src="userData.profile_image ? `${IMAGEURL}${userData.profile_image}` : avtar"
                                    width="100%" height="auto" class="img-fluid" />
                            </b-avatar>
                            <div class="text-right">
                                <b-dropdown size="lg" variant="none" class="profile-dnd" toggle-class="text-decoration-none"
                                    no-caret right>
                                    <template #button-content>
                                        <i class="fa fa-ellipsis-v mr-4" aria-hidden="true"></i>
                                    </template>
                                    <!-- <b-dropdown-item @click="$router.push({ path: '/candidate/edit-profile' })">Profile
                                        Setting</b-dropdown-item> -->
                                    <b-dropdown-item @click="$router.push({ path: '/candidate/settings' })">{{
                                        $gettext("Settings") }}</b-dropdown-item>
                                    <!-- <b-dropdown-item href="#"></b-dropdown-item> -->
                                </b-dropdown>

                            </div>
                            <div class="">
                                <p class="h-label m-0">
                                    {{ userData.user_username }}
                                </p>
                                <div style="    margin-top: -10px;" class="d-flex flex-wrap">
                                    <span class="generic-typography mt-2 pr-2">{{ userData.designation }}</span>
                                </div>
                                <p style="opacity: .5;" class="generic-typography m-0">
                                    {{ userData.country_name }}, {{ userData.city?.label }}
                                </p>
                                <!-- <div style="    margin-top: -10px;" class="d-flex flex-wrap">
                                    <span class="generic-typography mt-2 pr-2">User experience designer at apple</span>
                                    <span class="h-part-time mt-2">Part-time</span>
                                </div> -->
                                <div class="mt-2 d-flex zc-w-22" style="gap:10px">
                                    <button @click="gotoEditProfile" class="btn btn-user-profile1 btn-text-blue">
                                        {{ $gettext("Edit_Profile") }}
                                    </button>
                                    <button @click="share" class="btn btn-user-profile2 btn-text-white">
                                        {{ $gettext("Share_Profile") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-if="userJobs.length" style="gap: 10px; " class="zc-w-22 p-3 d-flex flex-nowrap  align-items-center">
                <h3 style="font-size: clamp(1.3rem,3vw,1.625rem);">
                    {{ $gettext("APPLIED_JOBS") }}
                </h3>
                <!-- <button style="width: 17.125rem;" class="btn btn-text-logo btn-user-profile3">
                    Referrals
                </button> -->
            </div>
            <!-- <b-row class="p-4">
                <b-col cols="12" lg="4" md="12" sm="12">


                </b-col>
            </b-row> -->
            <div class="user-card zc-w-22 my-2" v-for="(job, i) in userJobs" :key="i">
                <b-row>
                    <b-col col cols="12" lg="8" md="12" sm="12">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex justify-content-between">
                                <div style="margin-top: .5rem;">
                                    <b-avatar style="background: transparent;" rounded>
                                        <!-- <img :src="avtar"
                                                    width="75%" height="auto" class="img-fluid" /> -->
                                        <img :src="`${IMAGEURL}${job.company_logo_path}`" width="95%" height="95%"
                                            style="border-radius: 10px;" />
                                    </b-avatar>
                                </div>
                                <div class="px-3 align-items-center">
                                    <span style="cursor: pointer;" @click="gotoJobDetail(job.job_id)">
                                        <router-link :to="`/candidate/job-deatils/${job.job_id}`" class="h-job-name">
                                            {{ job.Job_title }}
                                        </router-link>
                                    </span>
                                    <div style="gap: 10px;" class="d-flex">
                                        <span class="h-company">{{ job?.company }}</span>
                                        <span class="h-part-time">{{ job?.jobType }}</span>
                                        <!-- <span class="h-exp">{{ job?.designation }}</span> -->
                                    </div>
                                </div>
                            </div>

                        </div>

                        <ul class="mt-3">
                            <!-- <li v-for="(desc, index) in job.jobDescription" :key="index" class="h-job-description">
                                {{ desc.description }}
                            </li> -->
                            <!-- <li class="h-job-description">
                                {{ job.jobDescription }}
                            </li> -->
                            <li class="h-job-description" v-for="(data, i) in job.jobDescription" :key="i">
                                <!-- {{ job.jobDescription.split(".") }} -->
                                {{ data }}
                            </li>
                        </ul>
                    </b-col>

                    <b-col col cols="12" lg="4" md="12" sm="12" class="top-bar">
                        <b-row>
                            <b-col col lg="12" md="6" sm="6" class="h-text-align">
                                <div class="h-location">
                                    <img style="opacity: .5;" src="@/assets/zaajira-candidate/assets/icons/location.svg"
                                        class="img-fluid" />
                                    {{ job.country }}, {{ job.city }}
                                </div>
                                <div class="h-posted-at ml-md-1">
                                    {{ $gettext("Posted") }} {{ job.postedAt }}
                                </div>
                            </b-col>
                            <b-col col lg="12" md="6" sm="6">
                                <div class="mt-lg-3 h-opening">
                                    {{ job.numberOfOpenings }} {{ $gettext("Opening") }}
                                </div>
                                <div class="h-currency">
                                    {{ job.currency }} {{ job.job_salary_range_from }}-{{ job.job_salary_range_to }}/M
                                    <!-- {{
                                        job.gender
                                    }} -->
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <div v-if="infiniteScrollLoader" class="d-flex align-items-center justify-content-center">
                <div class="infite-s-loader"> </div>
            </div>
            <div style="height:1rem;"></div>
        </div>
        <zcModal :is-open="isShare" @open="isShare = true" @close="isShare = false" :isCloseBTN="true"
            cumtomeStyle="position: absolute;min-width: 20rem;max-width: 26rem;width: 100%;top: 3rem;">

            <template v-slot:child>
                <div>
                    <h4>{{ $gettext("Share_this_profile") }}</h4>
                    <div class="social-icons-grid d-flex justify-content-between">
                        <ShareNetwork network="Whatsapp" :url="`Profile link: ${userProfile}${userData.user_id}`"
                            :title="userData.user_username ? `Name: ${userData.user_username}` : ''"
                            :description="userData.designation ? `Designation: ${userData.designation}` : ''"
                            @open="handleSubmit">
                            <div>
                                <i style="color:#000; font-size: 1.5rem;" class="fa fa-whatsapp"></i>
                            </div>
                        </ShareNetwork>
                        <ShareNetwork network="Facebook" :url="`Profile link: ${userProfile}${userData.user_id}`"
                            :title="userData.user_username ? `Name: ${userData.user_username}` : ''"
                            :description="userData.designation ? `Designation: ${userData.designation}` : ''"
                            @open="handleSubmit">
                            <div>
                                <i style="color:#000; font-size: 1.5rem;" class="fa fa-facebook"></i>
                            </div>
                        </ShareNetwork>
                        <ShareNetwork network="Twitter" :url="`Profile link: ${userProfile}${userData.user_id}`"
                            :title="userData.user_username ? `Name: ${userData.user_username}` : ''"
                            :description="userData.designation ? `Designation: ${userData.designation}` : ''"
                            @open="handleSubmit">
                            <div>
                                <i style="color:#000; font-size: 1.5rem;" class="fa fa-twitter"></i>
                            </div>
                        </ShareNetwork>
                        <ShareNetwork network="Linkedin" :url="`Profile link: ${userProfile}${userData.user_id}`"
                            :title="userData.user_username ? `Name: ${userData.user_username}` : ''"
                            :description="userData.designation ? `Designation: ${userData.designation}` : ''"
                            @open="handleSubmit">
                            <div>
                                <i style="color:#000; font-size: 1.5rem;" class="fa fa-linkedin"></i>
                            </div>
                        </ShareNetwork>
                    </div>
                </div>
            </template>
        </zcModal>
    </div>
</template>

<script>
import Header from '../../../components/Partials/Header.vue'
import zcModal from '../../../components/Modal/Modal.vue'
import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";
import { formattedNumber, convertToTimeSince } from '../Helper/HelperFunctions.js'
import { IMAGEURL, userProfile } from "@/config";
import avtar from '@/assets/zaajira-candidate/assets/bg-images/avtar-image.png'
export default {
    name: "UserProfile",
    components: { Header, zcModal },
    data() {
        return {
            avtar,
            IMAGEURL,
            userProfile,
            isShare: false,
            CityOption: [],
            userData: {
                user_id: null,
                profile_image: null,
                user_username: null,
                user_gender: null,
                designation: null,
                country_name: null,
                city: null,
            },
            currentpage: 1,
            perPage: 10,
            totalJobs: null,
            infiniteScrollLoader: false,
            userJobs: [
                // {
                //     Job_title: "Senior ui designer",
                //     company: "Amazon",
                //     jobType: "Part-time",
                //     designation: "Senior level",
                //     country: "Pakistan",
                //     city: "Islamabad",
                //     postedAt: "Posted 1 hr ago",
                //     jobDescription: [
                //         {
                //             description: "Collaborate with the marketing team to optimize conversion.",
                //         },
                //         {
                //             description: " Develop inspiring, persuasive, and convincing copy for a wide array of writing needs.",
                //         }
                //     ],
                //     numberOfOpenings: 10,
                //     currency: " TZS 800,000 - 1,000,00",
                //     gender: "M"
                // },
            ]
        }
    },
    beforeMount() {
        this.PreFilledData()
    },
    methods: {
        gotoEditProfile() {
            this.$router.push({ path: '/candidate/settings' });
        },
        gotoJobDetail(job_id) {
            console.log(job_id);
            this.$router.push({ path: `/candidate/job-deatils/${job_id}`, params: { job_id } });
        },
        async GetAllCityOfCountry(country_id) {
            console.log("this.country", country_id);
            if (country_id !== null) {
                const payload = {
                    country_id: country_id
                }
                // MasterServices.GetAllCityOfCountry(payload).then((res) => {
                //     console.log('City::', res);
                //     this.CityOption = res.data.data.map((city) => {
                //         return {
                //             label: city.city_name,
                //             city_id: city.city_id,
                //         };
                //     });

                // });
                try {
                    const cityRes = await MasterServices.GetAllCityOfCountry(payload)
                    this.CityOption = cityRes.data.data.map((city) => {
                        return {
                            label: city.city_name,
                            city_id: city.city_id,
                        };
                    });

                } catch (error) {
                    console.log("city::error", error);
                }
            }
        },
        async getAllMasterData() {
            try {
                const currencyRes = await MasterServices.GetAllCurrency()
                console.log('currencyRes', currencyRes);
                this.currencyOption = currencyRes.data.data
                const educationRes = await MasterServices.GetAllEducation()
                console.log('educationRes', educationRes.data.data.types);
                // const NationalityRes = await MasterServices.GetAllNationality()
                const CountryRes = await MasterServices.GetAllCountry()

            } catch (error) {

            }
        },
        async getAppliedJobs(user_id) {
            try {
                const payload = {
                    currentpage: 1,
                    perpage: 10,
                    user_id: user_id
                }
                const AllAppliedJobsRes = await CandidateService.GetAppliedJobs(payload)
                console.log("AllAppliedJobsRes.data.data", AllAppliedJobsRes.data.data.data);
                const appliedJobs = AllAppliedJobsRes.data.data.data;
                const appliedJobList = []


                appliedJobs.map(async (aj) => {
                    const apJob = {
                        job_id: aj.job_id,
                        company_logo_path: aj.company_logo_path,
                        Job_title: aj.job_title,
                        company: aj.company_long_name,
                        jobType: aj.job_type,
                        designation: "Senior level",
                        country: aj.country[0].country_name,
                        city: aj.city[0].city_name,
                        postedAt: convertToTimeSince(aj.job_start_date),
                        jobDescription: aj.job_description.split(/[.•]/).filter((item, i) => item !== "" && item !== "\n" && i < 3),
                        numberOfOpenings: aj.vacancy,
                        currency: aj.currency_code,
                        job_salary_range_from: await formattedNumber(aj.job_salary_range_from),
                        job_salary_range_to: await formattedNumber(aj.job_salary_range_to),
                        gender: aj.gender === "Female" ? "F" : "M"
                    }
                    this.userJobs.push(apJob)
                })

            } catch (error) {
                console.log(error);
            }
        },
        async PreFilledData() {
            // await this.getAllMasterData()
            const loader = this.$loading.show()
            const userData = this.$store.getters.getLoggedUser
            await this.getAppliedJobs(userData.userId)
            CandidateService.getUserByUserID(userData.userId)
                .then(async (res) => {
                    console.log("res>>>", res.data.data[0])
                    // this.education = this.educationOption.filter((ed) => ed.ms_edu_id === res.data.data[0].ms_edu_id)
                    // this.isExperience = res.data.data[0].experiance_is_active === 'Y' ? 'Yes' : 'No'
                    // this.Total_experience = res.data.data[0].experiance_year
                    // this.job_title = res.data.data[0].designation
                    // this.currency = this.currencyOption.filter((c) => c.currency_id === res.data.data[0].currency_id)
                    // this.current_salary = res.data.data[0].current_salary



                    this.userData.user_id = res.data.data[0].user_id;
                    this.userData.profile_image = res.data.data[0].profile_image;
                    this.userData.user_username = res.data.data[0].user_username;
                    this.userData.user_gender = res.data.data[0].user_gender;
                    this.userData.designation = res.data.data[0].designation;
                    this.userData.country_name = res.data.data[0].country_name;
                    await this.GetAllCityOfCountry(res.data.data[0].country_id)
                    this.userData.city = this.CityOption.filter((c) => c.city_id === res.data.data[0].city_id)[0]
                    // this.userData.city_id = res.data.data[0].city_id;

                    loader.hide();
                })
        },
        infinitScrollForJobs(payload) {
            this.infiniteScrollLoader = true
            CandidateService.GetAllJobsOrFilter(payload)
                .then((res) => {
                    console.log("all jobs", res.data);
                    console.log("all jobs", res.data.data.data);
                    this.totalJobs = res.data.data.total
                    console.log("totalJobs", this.totalJobs);
                    const allJobs = res.data.data.data
                    let filterJob = []
                    if (allJobs.length) {
                        allJobs.map(async (aj) => {
                            const apJob = {
                                job_id: aj.job_id,
                                company_logo_path: aj.company_logo_path,
                                Job_title: aj.job_title,
                                company: aj.company_long_name,
                                jobType: aj.job_type,
                                designation: "Senior level",
                                country: aj.country,
                                city: aj.city_name,
                                postedAt: convertToTimeSince(aj.job_start_date),
                                jobDescription: aj.job_description.split(/[.•]/).filter((item, i) => item !== "" && item !== "\n" && i < 3),
                                numberOfOpenings: aj.vacancy,
                                currency: aj.currency_code,
                                job_salary_range_from: await formattedNumber(aj.job_salary_range_from),
                                job_salary_range_to: await formattedNumber(aj.job_salary_range_to),
                                gender: aj.gender === "Female" ? "F" : "M"
                            }
                            this.userJobs.push(apJob)
                        })
                    }
                    this.infiniteScrollLoader = false
                })
                .catch((error) => {
                    this.infiniteScrollLoader = false
                    console.log(error);
                })
        },
        handleScroll(e) {
            // console.log(e);
            // console.log("offsetHeight", e.target.offsetHeight);
            // console.log("scrollHeight", e.target.scrollHeight);
            // console.log("e.target.scrollTop", e.target.scrollTop);
            // console.log("scrollHeight - offsetHeight", e.target.scrollHeight - e.target.offsetHeight);
            const Scroll = e.target.scrollHeight - e.target.offsetHeight <= e.target.scrollTop
            if (Scroll && !this.infiniteScrollLoader && this.userJobs.length < this.totalJobs) {
                console.log("scrolled");
                this.currentpage = this.currentpage + 1

                const payload = {
                    role_id: null,
                    user_id: null,
                    currentpage: this.currentpage,
                    perPage: this.perPage,
                    search: this.search,
                    filter: { filter: { filters: [] } }
                }
                console.log(payload);
                console.log("UserJobs", this.userJobs.length);
                console.log("totalJobs", this.totalJobs);
                this.infinitScrollForJobs(payload)

            }
        },
        share() {
            this.isShare = true
        },

        handleSubmit() {
            this.isShare = false
        },
    },
    mounted() {
        const jobsDiv = document.getElementById('h-userjob')
        const mainSection = document.getElementById('main-section')
        if (window.innerWidth <= 992) {
            console.log("mainSection::Saved job", mainSection);
            mainSection.addEventListener('scroll', this.handleScroll);
        } else {
            jobsDiv.addEventListener('scroll', this.handleScroll);

        }
    },
    beforeDestroy() {
        if (window.innerWidth <= 992) {
            const mainSection = document.getElementById('main-section')
            mainSection.removeEventListener('scroll', this.handleScroll);
        } else {
            const jobsDiv = document.getElementById('h-userjob')
            jobsDiv.removeEventListener('scroll', this.handleScroll);
        }
    },
}
</script>

<style>
.profile-dnd ul li a {
    font-size: .875rem;
    text-transform: capitalize;
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 600;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
</style>